.Action {
  align-items: stretch;
  background: var(--color-surface);
  border: var(--width-border) solid var(--color-primary);
  border-radius: 0.25rem;
  color: var(--color-primary);
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  line-height: 1;
  padding: 0.75rem;
  fill: none;
  stroke: var(--color-primary);
  stroke-width: 1.25px;
}

.Action:hover {
  color: var(--color-onPrimary);
  background: var(--color-primary);
  stroke: var(--color-onPrimary);
}

.Action:focus {
  color: var(--color-onPrimary);
  background: var(--color-primary);
  box-shadow: none;
  outline: none;
  stroke: var(--color-onPrimary);
}

.Action__Icon {
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
}

.Action__Title {
  display: flex;
  flex-direction: column;

}