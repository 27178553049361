.Dimensions {
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 0.5rem;
  font-size: 0.75rem;
}

.Dimensions span {
  color: var(--onBackground);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* NUMBER INPUT
============================== */
.Dimensions input[type="number"] {
  background: var(--color-interface);
  border: var(--width-border) solid var(--color-border);
  border-radius: 0.25rem;
  box-sizing: border-box;
  padding: 0.25rem;
  text-align: center;
  width: 4rem;
}

.Dimensions input[type="number"]:first-of-type {
  margin-right: 0.25rem;
}

.Dimensions input[type="number"]:last-of-type {
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}

.Dimensions input[type="number"]:hover {
  border-color: var(--color-border--hover);
  box-shadow: none;
  outline: none;
}

.Dimensions input[type="number"]:focus {
  border-color: var(--color-border--focus);
  box-shadow: none;
  outline: none;
}

.Dimensions input[type="number"]:read-only {
  background: var(--color-background);
  border-color: var(--color-border);
}

/* SELECT INPUT
============================== */
.Dimensions select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: var(--color-interface);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='292.4' height='292.4'%3E%3Cpath fill='%236e6e73' d='M287 69.4a17.6 17.6 0 0 0-13-5.4H18.4c-5 0-9.3 1.8-12.9 5.4A17.6 17.6 0 0 0 0 82.2c0 5 1.8 9.3 5.4 12.9l128 127.9c3.6 3.6 7.8 5.4 12.8 5.4s9.2-1.8 12.8-5.4L287 95c3.5-3.5 5.4-7.8 5.4-12.8 0-5-1.9-9.2-5.5-12.8z'/%3E%3C/svg%3E"),
    linear-gradient(
      to bottom,
      var(--color-interface) 0%,
      var(--color-interface) 100%
    );
  background-repeat: no-repeat, repeat;
  background-position: right 0.5em top 50%, 0 0;
  background-size: 0.5em auto, 100%;
  border: var(--width-border) solid var(--color-border);
  border-radius: 0.25rem;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  margin: 0 0 0 0.5rem;
  min-width: 12em;
  padding: 0.25rem;
  text-align: center;
}
.Dimensions select:hover {
  border-color: var(--color-border--hover);
}
.Dimensions select:focus {
  border-color: var(--color-border--focus);
  box-shadow: none;
  outline: none;
}
