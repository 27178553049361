.Canvas {
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  margin: 0 1rem 1rem;
  padding: 1rem;
  position: relative;
}

@media (min-width: 64rem) {
  .Canvas {
      margin: 0 2rem 2rem;
      padding: 2rem;
    }
}