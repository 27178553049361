/* INPUT
============================== */
.Input {
  align-items: center;
  display: flex;
  gap: 1rem;
  height: 2.5rem;
  line-height: 1;
  margin: 0.5rem 0;
  padding: 0;
  position: relative;
}

@media (min-width: 64rem) {
  .Input {
    margin: 1rem 0;
  }
}

.Input-label {
  flex: 1;
}

.Input-interface {
  flex: 1;
}

.Input-label label {
  text-transform: capitalize;
}

/* RANGE INPUT
============================== */
.Input--range {
}

.Input--range span {
  position: absolute;
  right: 25%;
  top: 1.25rem;
  transform: translate(50%, -50%);
  pointer-events: none;
}

.Input--range input[type="range"] {
  -webkit-appearance: none;
  margin: 0;
  width: 100%;
}

.Input--range input[type="range"]:focus {
  outline: none;
  box-shadow: none;
}

.Input--range input[type="range"]::-webkit-slider-runnable-track {
  background: var(--color-interface);
  border: none;
  border-radius: 0.25rem;
  box-shadow: inset 0px 0px 0px var(--width-border) var(--color-border);
  cursor: pointer;
  height: 2.5rem;
  width: 100%;
}
.Input--range input[type="range"]:hover::-webkit-slider-runnable-track {
  background: var(--color-interface--hover);
  box-shadow: inset 0px 0px 0px var(--width-border) var(--color-border--hover);
}
.Input--range input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--color-interface--focus);
  box-shadow: inset 0px 0px 0px var(--width-border) var(--color-border--focus);
  outline: none;
}

.Input--range input[type="range"]::-moz-range-track {
  background: var(--color-interface);
  border: none;
  border-radius: 0.25rem;
  box-shadow: inset 0px 0px 0px var(--width-border) var(--color-border);
  cursor: pointer;
  height: 2.5rem;
  width: 100%;
}
.Input--range input[type="range"]:hover::-moz-range-track {
  background: var(--color-interface--hover);
  box-shadow: inset 0px 0px 0px var(--width-border) var(--color-border--hover);
}
.Input--range input[type="range"]:focus::-moz-range-track {
  background: var(--color-interface--focus);
  box-shadow: inset 0px 0px 0px var(--width-border) var(--color-border--focus);
  outline: none;
}

.Input--range input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: var(--color-interface);
  border: var(--width-border) solid var(--color-border);
  border-radius: 0.25rem;
  box-shadow: none;
  cursor: pointer;
  height: 2.5rem;
  width: 1rem;
}
.Input--range input[type="range"]:hover::-webkit-slider-thumb {
  border-color: var(--color-border--hover);
}
.Input--range input[type="range"]:focus::-webkit-slider-thumb {
  border-color: var(--color-border--focus);
  box-shadow: none;
  outline: none;
}

.Input--range input[type="range"]::-moz-range-thumb {
  background: var(--color-interface);
  border: var(--width-border) solid var(--color-border);
  border-radius: 0.25rem;
  box-shadow: none;
  cursor: pointer;
  height: 2.5rem;
  width: 1rem;
}
.Input--range input[type="range"]:hover::-moz-range-thumb {
  border-color: var(--color-border--hover);
}
.Input--range input[type="range"]:focus::-moz-range-thumb {
  border-color: var(--color-border--focus);
  box-shadow: none;
  outline: none;
}

/* SELECT INPUT
============================== */
.Input--select {
}

.Input--select select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: var(--color-interface);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='292.4' height='292.4'%3E%3Cpath fill='%236e6e73' d='M287 69.4a17.6 17.6 0 0 0-13-5.4H18.4c-5 0-9.3 1.8-12.9 5.4A17.6 17.6 0 0 0 0 82.2c0 5 1.8 9.3 5.4 12.9l128 127.9c3.6 3.6 7.8 5.4 12.8 5.4s9.2-1.8 12.8-5.4L287 95c3.5-3.5 5.4-7.8 5.4-12.8 0-5-1.9-9.2-5.5-12.8z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5em top 50%;
  background-size: 0.5em auto;
  border: var(--width-border) solid var(--color-border);
  border-radius: 0.25rem;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0.5rem;
  text-align: center;
  width: 100%;
}
.Input--select select:hover {
  background-color: var(--color-interface--hover);
  border-color: var(--color-border--hover);
}
.Input--select select:focus {
  background-color: var(--color-interface--focus);
  border-color: var(--color-border--focus);
  outline: none;
}

/* COLOR INPUT
============================== */
.Input--color {
}

.Input--color button {
  align-items: center;
  background-color: var(--color-interface);
  border: var(--width-border) solid var(--color-border);
  border-radius: 0.25rem;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0.5rem;
  text-transform: uppercase;
  width: 100%;
}

.Input--color button:hover {
  background-color: var(--color-interface--hover);
  border-color: var(--color-border--hover);
}
.Input--color button:focus {
  background-color: var(--color-interface--focus);
  border-color: var(--color-border--focus);
  outline: none;
}

.Input--color button .color-circle {
  box-shadow: inset 0 0 0 var(--width-border) rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5em;
  height: 1.25em;
  width: 1.25em;
}
.Input--color button .color-circle {
  display: inline-block;
  margin-left: 0.5em;
}

.Input--color .color-popup {
  position: absolute;
  right: 0;
  z-index: 2;
}

.Input--color .color-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
