.App {
  display: grid;
  min-height: 100vh;
}

.App-main {
  background: var(--color-background);
  display: grid;
  grid-template-rows: min-content min-content;
}

.App-aside {
  background: var(--color-surface);
  border-top: 1px solid var(--color-divider);
  padding: 0rem 1rem;
}

@media (min-width: 48rem) {
  .App {
    grid-template-rows: auto;
    grid-template-columns: 3fr 25rem;
  }
  .App-aside {
    border-left: 1px solid var(--color-divider);
    border-top: none;
  }
}

@media (min-width: 64rem) {
  .App {
    grid-template-columns: 3fr 30rem;
  }
  .App-aside {
    padding: 1rem 2rem;
  }
}
